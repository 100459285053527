import styled from "styled-components";
import { mediaRules } from "MuiTheme/Breakpoints";
import palette from "palette";

const PageContainer = styled.div`
  position: absolute;
  left: 80px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  padding: 30px;
  min-height: 100vh;
  min-width: 320px;
  max-width: 2100px;
  overflow-y: auto;
  overflow-x: hidden;
  @media ${mediaRules.sm} {
    left: 0px;
    top: 80px;
    min-height: calc(100vh - 80px);
    padding: 15px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    @media ${mediaRules.sm} {
      width: 7px;
    }
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    @media ${mediaRules.sm} {
      border-radius: 7px;
    }
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${palette.primary};
  }
`;

export default PageContainer;
